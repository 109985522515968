import React from "react";
import "./Category.css";
import Timing from "./Timing";
import { RaceCompetitor } from "@/types/LeaderboardTypes";

interface CategoryProps {
  header: string;
  data: RaceCompetitor[];
}

const Category: React.FC<CategoryProps> = ({ header, data }) => {
  var result = [];
  if (data !== undefined) {
    for (var i in data) result.push(data[i]);
    localStorage.setItem("CatData", JSON.stringify(result));
  }

  if (result.length > 0) {
    result.sort(function compare(a, b) {
      let timeA = a.finishTime;
      let timeB = b.finishTime;
      return timeA - timeB;
    });
  }

  return (
    <div className="category">
      <div className="category-subheader">{header}</div>
      <div className="timings">
        {result.length > 0 ? (
          result
            .slice(0, 10)
            .map((result, resultIndex) => (
              <Timing
                name={result.name}
                time={result.finishTime.toFixed(1)}
                position={resultIndex + 1}
                key={resultIndex}
              />
            ))
        ) : (
          <div className="empty-box">&#8203;</div>
        )}
      </div>
    </div>
  );
};

export default Category;
