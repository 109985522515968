const Snow = () => {
  return (
    <div className="snowContainer">
      <div className="initial-snow">
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        {/* <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div>
        <div className="snow">&#10052;</div> */}
      </div>
    </div>
  );
};

export default Snow;
