import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Results from "./pages/Results";
import Test from "./pages/Test";
import "./App.scss";
import "./styles/Snow.scss";
import "./styles/Leaderboard.scss";
import "./styles/Timing.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Results />} />
          <Route path="/test" element={<Test />} />
          <Route path="/*" element={<Results />} />
        </Routes>
      </Router>
    );
  }
}
export default App;
