import React from "react";
import Leaderboard from "../components/Leaderboard";
import Snow from "../components/Snow";

export default function Results() {
  return (
    <>
      <Snow />
      <Leaderboard />
    </>
  );
}
